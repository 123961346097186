import { createApp } from 'vue'
import KeycloakPlugin from 'KPackages/vue-plugins/v3/keycloak/keycloak'
import router from './router'
import store from './store'

import App from './App.vue'
import './main.scss'
import '/var/www/packages/css/shared_styles.css'
import '/var/www/packages/css/shared_scss.scss'

const app = createApp(App)
app.use(KeycloakPlugin);

router.$app = app;
app.use(router)

store.$app = app;
app.use(store)

window.$ = window.jQuery = require('jquery');

const vm = app.mount('#app')
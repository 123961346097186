import { createRouter, createWebHistory } from 'vue-router'
import urls from 'KPackages/api/account/urls'
import * as libsmKeycloak from 'KPackages/libsm-keycloak'
import * as libsmServices from 'KPackages/libsm-services'
import store from '../store'

const Dashboard = () => import('../views/Dashboard.vue');
const DashboardStaff = () => import('../views/DashboardStaff.vue');

const routes = [
  {
    path: urls.spa.dashboard,
    name: 'dashboard',
    component: Dashboard,
    meta: {
      isAuthenticated: true
    },
    children: [
      {
        path: urls.spa.dashboard_staff,
        name: 'dashboard_staff',
        component: DashboardStaff,
        meta: {
          isStaff: true
        }
      }
    ]
  },
]

//const AccountBaseUrl = location.protocol +'//'+ process.env.VUE_APP_ACCOUNT_DOMAIN
const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  let checkAutomaticRedirect = async (tokenParsed) => {
    if(to.name === 'dashboard') {
      // redirect to workspace if only 1
      var groups = libsmKeycloak.getUserKeycloakGroups(tokenParsed, 'kseating');
      if (groups.length === 1) {
        var _settings = await store.dispatch('services/readSettings', {
          service: 'kseating',
          workspace: groups[0].workspace
        });
        window.location.href = libsmServices.getMonolithUrl(_settings.WORKSPACE_DOMAIN);
        next(false)
        return;
      }
    }
  }
  if (to.meta.isAuthenticated || to.meta.isStaff) {
    // se l'utente non è autenticato
    if (!router.$app.keycloakManager.authenticated) {
      try {
        const authenticated = await router.$app.keycloakManager.init()
        if (authenticated) {
          // controllo se sono superuser
          if(libsmKeycloak.isStaff(router.$app.keycloakManager.keycloak.tokenParsed)) {
            next({name: 'dashboard_staff'})
            return;
          } else {
            var tokenParsed = router.$app.keycloakManager.keycloak.tokenParsed;
            await checkAutomaticRedirect(tokenParsed)
            next()
            return;
          }
        } else {
          next(false)
          return;
        }
      } catch (e) {
        console.error(e)
        next(false)
        return;
      }
    } else {
      // l'utente è autenticato
      var tokenParsed = router.$app.keycloakManager.keycloak.tokenParsed;
      if (to.meta.isStaff && !libsmKeycloak.isStaff(tokenParsed)) {
        next(false)
        return;
      }
      await checkAutomaticRedirect(tokenParsed)
      next()
      return;
    }
  } else {
    // This page did not require authentication
    next()
  }
})

export default router

function getProcessEnvVar (varName) {
  // Returns the value of a process.env var
  // Raise an error if the value is null, undefined or ''
  var v = process.env[varName];
  if (v === undefined || v === null || v === "") {
    throw new Error(varName + " not found in process.env");
  }
  return v;
}

const getMonolithUrl = (domain) => {
  var ret = location.protocol + '//' + domain;
  return ret; 
}

const getSharedDataDomain = () => {
  return getProcessEnvVar('VUE_APP_SHARED_DATA_DOMAIN');
}

// const getWorkspaceDomainFromName = (workspace) => {
//   var varName = 'VUE_APP_KSEATING_WORKSPACE_DOMAIN_' + workspace.toUpperCase();
//   return getProcessEnvVar(varName);
// }

const isServerBackend = () => {
  return location.pathname.startsWith('/kmanage')
}

const protocol = location.protocol;

export {
  getMonolithUrl,
  getProcessEnvVar,
  getSharedDataDomain,
  // getWorkspaceDomainFromName,
  isServerBackend,
  protocol,
}
